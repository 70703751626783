import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, compose, applyMiddleware } from '@reduxjs/toolkit'
import { sessionService } from "redux-react-session";
import { apiSlice } from "./services/apiSlice";
import reduxThunk from 'redux-thunk';
import { I18nextProvider } from 'react-i18next';
import i18n from './config/i18n';
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

// import './index.css';
import App from './components/App';
import reducers from './reducers';
import { verifyToken } from './lib/apis/shadankun';

import { icons } from './assets/icons'

React.icons = icons;

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  reducers,
  composeEnhancers(applyMiddleware(reduxThunk, apiSlice.middleware))
);

const validateSession = (session) => {
  return verifyToken(session).then(isValid => {
    return isValid;
  });
};

// Init the session service
sessionService.initSessionService(store, { driver: 'COOKIES', validateSession });

// Init Sentry service
Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new BrowserTracing()],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  // リリース時に出るerrorは送らない
  ignoreErrors: [
    "ChunkLoadError",
    "Unexpected token '<'",
  ],
});

ReactDOM.render(
  <Provider store={store}>
    <I18nextProvider i18n={i18n}>
      <App/>
    </I18nextProvider>
  </Provider>,
  document.querySelector('#root')
);
