import shadankun from "../lib/apis/shadankun";
import {
  SEND_PASSWORD_RESET_MAIL,
  RESET_PASSWORD,
} from "./types";

export const sendPasswordResetMail = (formValues) => async (dispatch) => {
  const response = await shadankun.post('/api/auth/password', { ...formValues });
  dispatch({ type: SEND_PASSWORD_RESET_MAIL, payload: response.data });
};

export const resetPassword = (formValues) => async (dispatch) => {
  const response = await shadankun.put('/api/auth/password', { ...formValues });
  dispatch({ type: RESET_PASSWORD, payload: response.data });
};
