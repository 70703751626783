import { createSlice } from "@reduxjs/toolkit";

const unauthorizedErrorSlice = createSlice({
  name: 'unauthorizedError',
  initialState: {
    response: null
  },
  reducers: {
    setUnauthorizedError(state, action) {
      return { response: action.payload };
    }
  }
});

export const { setUnauthorizedError } = unauthorizedErrorSlice.actions;
export const selectUnauthorizedError = (state) => state.unauthorized_error;

export default unauthorizedErrorSlice.reducer;
