import { createSlice } from "@reduxjs/toolkit";

const serverErrorSlice = createSlice({
  name: 'serverError',
  initialState: {
    response: null
  },
  reducers: {
    setServerError(state, action) {
      return { response: action.payload };
    }
  }
});

export const { setServerError } = serverErrorSlice.actions;
export const selectServerError = (state) => state.server_error;

export default serverErrorSlice.reducer;
