import React, { useLayoutEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import {
  CToast,
  CToastHeader,
  CToastBody
} from "@coreui/react";

import { hideErrorToast } from '../services/errorToastSlice';

const ErrorToast = () => {
  const dispatch = useDispatch();
  const header = useSelector((store) => store.error_toast.header);
  const body = useSelector((store) => store.error_toast.body);

  useLayoutEffect(() => {
      window.scrollTo(0, 0);
  });

  return (
    <div className="position-absolute" style={{zIndex: 9999, top: '30%', left: '50%', transform: `translateX(-50%)`}}>
      <CToast autohide={false} show={true} color='dark' fade={true}>
          <CToastHeader className={"h6"} closeButton={true} onClick={() => dispatch(hideErrorToast())}>
            {header}
          </CToastHeader>
          <CToastBody style={{textAlign: 'center', whiteSpace: 'pre-wrap'}}>
            {body}
          </CToastBody>
      </CToast>
    </div>
  )
};

export default ErrorToast;
