import React, { useState } from "react";
import { Field, reduxForm } from "redux-form";
import {
  CForm,
  CInputGroup,
  CInputGroupPrepend,
  CInputGroupText,
  CInput,
  CInvalidFeedback,
  CRow,
  CCol,
  CButton,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";

const PasswordResetMailForm = (props) => {
  const [isSubmitted, setIsSubmitted] = useState(false);

  const renderError = ({ error, touched }) => {
    if (touched && error) {
      return (
        <CInvalidFeedback
          className="help-block"
          style={{ display: "block" }}
          color="danger"
        >
          {error}
        </CInvalidFeedback>
      );
    }
  };

  const renderInput = ({ input, label, type, icon, meta }) => {
    return (
      <CInputGroup className="mb-3">
        <CInputGroupPrepend>
          <CInputGroupText>
            <CIcon name={icon} />
          </CInputGroupText>
        </CInputGroupPrepend>
        <CInput {...input} type={type} placeholder={label} />
        {renderError(meta)}
      </CInputGroup>
    );
  };

  const onSubmit = (formValues) => {
    formValues.redirect_url = process.env.REACT_APP_ENDPOINT + "password/edit";
    props.onSubmit(formValues);
    setIsSubmitted(true);
  };

  let message;
  if (isSubmitted) {
    message = (
      <div className="alert alert-success" role="alert">
        メールを送信しました。
      </div>
    );
  }

  return (
    <CForm onSubmit={props.handleSubmit(onSubmit)}>
      <h1>パスワードリセット</h1>
      <p className="text-muted">
        入力されたメールアドレス宛にパスワードリセット用リンクをメール送信します。
      </p>
      {message}
      <Field
        component={renderInput}
        label="メールアドレス"
        name="email"
        type="email"
        icon="cil-envelope-closed"
      />
      <CRow>
        <CCol xs="6">
          <CButton color="primary" disabled={props.submitting} type="submit">
            送信
          </CButton>
        </CCol>
      </CRow>
    </CForm>
  );
};

const validate = (formValues) => {
  const errors = {};

  if (!formValues.email) {
    errors.email = "メールアドレスを入力してください";
  }

  return errors;
};

export default reduxForm({
  form: "passwordResetMailForm",
  validate,
})(PasswordResetMailForm);
