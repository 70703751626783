import { createSlice } from "@reduxjs/toolkit";

const notificationFiltersSlice = createSlice({
  name: 'notificationFilters',
  initialState: {
    info_category: '',
    title: '',
    contents: '',
  },
  reducers: {
    setNotificationFilter(state, action) {
      return action.payload;
    }
  }
});

export const { setNotificationFilter } = notificationFiltersSlice.actions;
export const selectNotificationFilter = (state) => state.notification_filters;

export default notificationFiltersSlice.reducer;
