import i18n from 'i18next';
import jaLabel from '../locales/ja/label.json';
import { initReactI18next } from "react-i18next";

const resources = {
  ja: {
    label: jaLabel
  }
};

i18n
  .use(initReactI18next)
  .init({
    lng: 'ja',
    fallbackLng: 'ja',
    debug: true,
    interpolation: {
      escapeValue: false
    },
    react: {
      useSuspense: true,
      wait: true,
    },
    resources: resources
  });

export default i18n;
