import { createSlice } from "@reduxjs/toolkit";

const appConfigSlice = createSlice({
  name: 'appConfigs',
  initialState: {
    sidebarShow: 'responsive'
  },
  reducers: {
    setAppConfigs(state, action) {
      return action.payload;
    }
  }
});

export const { setAppConfigs } = appConfigSlice.actions;
export const selectAppConfigs = (state) => state.app_configs;

export default appConfigSlice.reducer;
