import React from "react";
import { CFooter } from "@coreui/react";

const Footer = () => (
    <CFooter fixed={false}>
      <div>
        <a href="https://www.shadan-kun.com/" target="_blank" rel="noopener noreferrer">攻撃遮断くん</a>
        <span className="ml-1">&copy; 2022 Cyber Security Cloud, Inc.</span>
      </div>
    </CFooter>
);

export default React.memo(Footer);
