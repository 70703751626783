import { createSlice } from "@reduxjs/toolkit";

const analysisFiltersSlice = createSlice({
  name: 'analysisFilters',
  initialState: {
    hostKeys: [],
    targetPeriod: 'past1m'
  },
  reducers: {
    setAnalysisFilter(state, action) {
      return action.payload;
    }
  }
});

export const { setAnalysisFilter } = analysisFiltersSlice.actions;
export const selectAnalysisFilter = (state) => state.analysis_filters;

export default analysisFiltersSlice.reducer;
