import React, { Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { CContainer, CFade, CAlert } from "@coreui/react";
import { useDispatch, useSelector } from 'react-redux';

// routes config
import routes from "../config/routes";
import { usePermission } from "../lib/permission";
import { selectServerError, setServerError } from '../services/serverErrorSlice';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

const Content = () => {
  const { currentCustomer } = usePermission();
  const dispatch = useDispatch();
  const serverError = useSelector(selectServerError);

  const handleClose = () => {
    dispatch(setServerError(null));
  };

  if (!currentCustomer) {
    return null;
  }
  return (
    <main className="c-main">
      <CContainer fluid>
        <Suspense fallback={loading}>
          <Switch>
            {routes.map((route, idx) => {
              return route.component && (
                <Route
                  key={idx}
                  path={route.path}
                  exact={route.exact}
                  name={route.name}
                  render={props => (
                    <CFade>
                      <CAlert show={serverError.response ? true : false} color="primary" closeButton onClick={handleClose}>
                        {/* TOBE: ベターな文言があれば随時修正 */}
                        予期せぬエラーが発生しました
                      </CAlert>
                      <route.component
                        customerCode={currentCustomer.customer_code}
                        { ...props}
                      />
                    </CFade>
                  )}
                />
              )
            })}
            <Redirect from="/" to={"/console/" + currentCustomer.customer_code + "/dashboard"}/>
          </Switch>
        </Suspense>
      </CContainer>
    </main>
  );
};

export default React.memo(Content);
