import { combineReducers } from '@reduxjs/toolkit';
import { apiSlice } from "../services/apiSlice";
import { reducer as formReducer } from 'redux-form';
import { sessionReducer } from "redux-react-session";
import analysisFilterReducer from "../services/filters/analysisFilterSlice";
import appConfigReducer from "../services/appConfigSlice";
import attackLogFilterReducer from "../services/filters/attackLogFilterSlice";
import auditFilterReducer from "../services/filters/auditFilterSlice";
import authReducer from "./authReducer";
import monitoringAgentFilterReducer from "../services/filters/monitoringAgentFilterSlice";
import serverTypeRuleFilterReducer from "../services/filters/serverTypeRuleFilterSlice";
import srvWafConfigFilterReducer from "../services/filters/srvWafConfigFilterSlice";
import notificationFilterReducer from "../services/filters/notificationFilterSlice";
import unauthorizedErrorReducer from '../services/unauthorizedErrorSlice';
import serverErrorReducer from '../services/serverErrorSlice';
import errorToastReducer from '../services/errorToastSlice';

const appReducer = combineReducers({
  [apiSlice.reducerPath]: apiSlice.reducer,
  analysis_filters: analysisFilterReducer,
  app_configs: appConfigReducer,
  attack_log_filters: attackLogFilterReducer,
  audit_filters: auditFilterReducer,
  auth: authReducer,
  form: formReducer,
  monitoring_agent_filters: monitoringAgentFilterReducer,
  session: sessionReducer,
  server_type_rule_filters: serverTypeRuleFilterReducer,
  srv_waf_config_filters: srvWafConfigFilterReducer,
  notification_filters: notificationFilterReducer,
  server_error: serverErrorReducer,
  unauthorized_error: unauthorizedErrorReducer,
  error_toast: errorToastReducer
});

export default (state, action) => {
  if (action.type === 'SIGN_OUT') {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
}
