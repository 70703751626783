import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  host_key: '',
  id: '',
  agent_status: '',
  memo: ''
};

const monitoringAgentFiltersSlice = createSlice({
  name: 'monitoringAgentFilters',
  initialState,
  reducers: {
    setMonitoringAgentFilter(state, action) {
      return action.payload;
    }
  }
});

export const { setMonitoringAgentFilter } = monitoringAgentFiltersSlice.actions;
export const selectMonitoringAgentFilter = (state) => state.monitoring_agent_filters;

export default monitoringAgentFiltersSlice.reducer;
