import { createSlice } from "@reduxjs/toolkit";

const serverTypeRuleFiltersSlice = createSlice({
  name: 'serverTypeRuleFilters',
  initialState: {
    ruleId: '',
    exRule: '',
    ruleAction: '',
    threatCategories: []
  },
  reducers: {
    setServerTypeRuleFilter(state, action) {
      return action.payload;
    }
  }
});

export const { setServerTypeRuleFilter } = serverTypeRuleFiltersSlice.actions;
export const selectServerTypeRuleFilter = (state) => state.server_type_rule_filters;

export default serverTypeRuleFiltersSlice.reducer;
