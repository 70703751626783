import { createSlice } from "@reduxjs/toolkit";

const srvWafConfigFiltersSlice = createSlice({
  name: 'srvWafConfigFilters',
  initialState: {
    id: '',
    agent_status: ''
  },
  reducers: {
    setSrvWafConfigFilter(state, action) {
      return action.payload;
    }
  }
});

export const { setSrvWafConfigFilter } = srvWafConfigFiltersSlice.actions;
export const selectSrvWafConfigFilter = (state) => state.srv_waf_config_filters;

export default srvWafConfigFiltersSlice.reducer;
