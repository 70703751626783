import React from 'react';
import { useSelector, useDispatch } from "react-redux";
import {
  CSidebar,
  CSidebarBrand,
  CSidebarNav,
  CSidebarMinimizer,
  CSidebarNavDropdown,
  CSidebarNavItem,
  CSidebarNavTitle
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { AllowedTo, usePermission } from "../lib/permission";
import { Permission } from "../lib/permission/config";
import { selectAppConfigs, setAppConfigs } from "../services/appConfigSlice";

const Sidebar = () => {
  const dispatch = useDispatch();
  const appConfigs = useSelector(selectAppConfigs);
  const { currentCustomer } = usePermission();
  if (!currentCustomer) {
    return null;
  }
  const customerCode = currentCustomer.customer_code;

  return (
    <CSidebar
      show={appConfigs.sidebarShow}
      onShowChange={(val) => dispatch(setAppConfigs({ sidebarShow: val }))}
    >
      <CSidebarBrand className="d-md-down-none" to="/">
        <CIcon
          className="c-sidebar-brand-full"
          src="/server_type.png"
          height={35}
        />
      </CSidebarBrand>
      <CSidebarNav>
        <CSidebarNavTitle>ユーザーメニュー</CSidebarNavTitle>
        <CSidebarNavItem
          name="ダッシュボード"
          to={"/console/" + customerCode + "/dashboard"}
          icon="cil-speedometer"
        />
        <CSidebarNavItem
          name="分析ボード"
          to={"/console/" + customerCode + "/analysis"}
          icon="cil-chart-pie"
        />
        <CSidebarNavItem
          name="攻撃ログ"
          to={"/console/" + customerCode + "/attack_logs"}
          icon="cil-bolt-circle"
        />
        {/*<CSidebarNavItem*/}
        {/*  name="レポート"*/}
        {/*  to={"/console/" + customerCode + "/reports"}*/}
        {/*  icon="cil-bar-chart"*/}
        {/*/>*/}
        {
          currentCustomer.customer_type !== 'distributor' &&
          <CSidebarNavItem
            name="WAF設定"
            to={"/console/" + customerCode + "/srv/waf_configs"}
            icon="cil-shield-alt"
          />
        }
        <CSidebarNavItem
          name="エージェント管理"
          to={"/console/" + customerCode + "/srv/monitoring_agents"}
          icon="cil-spreadsheet"
        />
        <CSidebarNavItem
          name="設定変更履歴"
          to={"/console/" + customerCode + "/audit_logs"}
          icon="cil-history"
        />
        <AllowedTo perform={Permission.ENABLE_OWNER_SCOPE}>
          <CSidebarNavTitle>オーナーメニュー</CSidebarNavTitle>
          <CSidebarNavDropdown name="アカウント管理" icon="cil-people">
            <AllowedTo perform={Permission.READ_USER}>
              <CSidebarNavItem
                name="ユーザー管理"
                to="/users"
              />
            </AllowedTo>
            <AllowedTo perform={Permission.READ_USER_GROUP}>
              <CSidebarNavItem
                name="グループ管理"
                to="/user_groups"
              />
            </AllowedTo>
          </CSidebarNavDropdown>
        </AllowedTo>
        <AllowedTo perform={Permission.ENABLE_AGENT_SCOPE}>
          <CSidebarNavTitle>管理者メニュー</CSidebarNavTitle>
          <CSidebarNavDropdown name="アカウント管理" icon="cil-people">
            <AllowedTo perform={Permission.READ_USER}>
              <CSidebarNavItem
                name="ユーザー管理"
                to="/users"
              />
            </AllowedTo>
            <AllowedTo perform={Permission.READ_USER_GROUP}>
              <CSidebarNavItem
                name="グループ管理"
                to="/user_groups"
              />
            </AllowedTo>
          </CSidebarNavDropdown>
        </AllowedTo>
        <AllowedTo perform={Permission.ENABLE_AGENT_SCOPE}>
          <CSidebarNavDropdown name="契約管理" icon="cil-file">
            <AllowedTo perform={Permission.READ_DISTRIBUTOR}>
              <CSidebarNavItem
                name="代理店管理"
                to="/distributors"
              />
            </AllowedTo>
            <AllowedTo perform={Permission.READ_CUSTOMER}>
              <CSidebarNavItem
                name="顧客管理"
                to="/customers"
              />
            </AllowedTo>
            <AllowedTo perform={Permission.READ_LICENSE}>
              <CSidebarNavItem
                name="ライセンス管理"
                to="/licenses"
              />
            </AllowedTo>
            <AllowedTo perform={Permission.READ_BILLING_DESTINATION}>
              <CSidebarNavItem
                name="請求先情報管理"
                to="/billing_destinations"
              />
            </AllowedTo>
          </CSidebarNavDropdown>
          <AllowedTo perform={Permission.ENABLE_ADMIN_SCOPE}>
            <CSidebarNavDropdown name="各種管理" icon="cil-settings">
              <AllowedTo perform={Permission.READ_INFORMATION}>
                <CSidebarNavItem
                  name="お知らせ管理"
                  to="/information"
                />
              </AllowedTo>
              <AllowedTo perform={Permission.READ_WAF_RULE}>
                <CSidebarNavItem
                  name="WAFルールマスタ"
                  to="/waf_rules"
                />
              </AllowedTo>
              <AllowedTo perform={Permission.READ_WAF_RULE_HISTORY}>
                <CSidebarNavItem
                  name="WAFルール履歴"
                  to="/waf_rule_histories"
                />
              </AllowedTo>
              <AllowedTo perform={Permission.READ_SRV_PLATFORM}>
                <CSidebarNavItem
                  name="監視プラットフォーム管理"
                  to="/srv_platforms"
                />
              </AllowedTo>
              {/*<CSidebarNavItem*/}
              {/*  name="Webプラットフォーム管理"*/}
              {/*  to="/wplatforms"*/}
              {/*/>*/}
              {/*<AllowedTo perform={Permission.READ_PRICE_PLAN}>*/}
              {/*  <CSidebarNavItem*/}
              {/*    name="料金プランマスタ"*/}
              {/*    to="/price_plans"*/}
              {/*  />*/}
              {/*</AllowedTo>*/}
            </CSidebarNavDropdown>
          </AllowedTo>
        </AllowedTo>
      </CSidebarNav>
      <CSidebarMinimizer className="c-d-md-down-none"/>
    </CSidebar>
  );
};

export default React.memo(Sidebar);
