import { apiSlice } from "./apiSlice";

export const customerApi = apiSlice.enhanceEndpoints({
  addTagTypes: ["Customers"]
}).injectEndpoints({
  endpoints: (build) => ({
    getCustomers: build.query({
      query: () => "v1/customers",
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: "Customers", id })),
              { type: 'Customers', id: 'LIST' }
            ]
          : [{ type: 'Customers', id: 'LIST' }]
    }),
    addCustomer: build.mutation({
      query(body) {
        return {
          url: `v1/customers`,
          method: "POST",
          body
        };
      },
      invalidatesTags: [{ type: 'Customers', id: 'LIST' }]
    }),
    getCustomer: build.query({
      query: (id) => `v1/customers/${id}`,
      providesTags: (result, error, id) => [{ type: "Customers", id }]
    }),
    updateCustomer: build.mutation({
      query(data) {
        const { id, ...body } = data;
        return {
          url: `v1/customers/${id}`,
          method: "PUT",
          body
        };
      },
      invalidatesTags: (result, error, { id }) => [{ type: "Customers", id }]
    }),
    deleteCustomer: build.mutation({
      query(id) {
        return {
          url: `v1/customers/${id}`,
          method: "DELETE"
        };
      },
      invalidatesTags: (result, error, id) => [{ type: "Customers", id }]
    })
  })
});


export const {
  useGetCustomersQuery,
  useGetCustomerQuery,
  useAddCustomerMutation,
  useUpdateCustomerMutation,
  useDeleteCustomerMutation
} = customerApi;
