import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';

const PublicRoute = ({ component, exact = false, path }) => {
  return <Route
    exact={exact}
    path={path}
    render={props => (
        React.createElement(component, props)
    )}
  />;
};

const { object, bool, string, func } = PropTypes;

PublicRoute.prototype = {
  component: func.isRequired,
  exact: bool,
  path: string.isRequired,
  location: object
};

export default PublicRoute;
