import React, { useCallback } from 'react';
import { Field, reduxForm } from 'redux-form';
import {
  CButton,
  CCol,
  CForm,
  CInput,
  CInputGroup,
  CInputGroupPrepend,
  CInputGroupText,
  CInvalidFeedback,
  CRow
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import history from "../../utils/history";

const PasswordResetForm = ({ handleSubmit, submitting, onSubmit }) => {
  const renderError = ({ error, touched }) => {
    if (touched && error) {
      return (
        <CInvalidFeedback className="help-block" style={{ display: 'block' }} color="danger">{error}</CInvalidFeedback>
      );
    }
  }

  const renderInput = useCallback(({ input, label, type, icon, meta }) => {
    return (
      <CInputGroup className="mb-3">
        <CInputGroupPrepend>
          <CInputGroupText>
            <CIcon name={icon} />
          </CInputGroupText>
        </CInputGroupPrepend>
        <CInput {...input} type={type} placeholder={label} />
        {renderError(meta)}
      </CInputGroup>
    );
  }, []);

  const formSubmit = (formValues) => {
    let urlParamStr = window.location.search;
    let params = {};
    if (urlParamStr) {
      urlParamStr = urlParamStr.substring(1);

      urlParamStr.split('&').forEach( param => {
        const temp = param.split('=');
        params = {
          ...params,
          [temp[0]]: temp[1]
        };
      })
    }
    formValues.reset_password_token = params['reset_password_token'];
    onSubmit(formValues);
    history.push('/login');
  };

  return (
    <CForm onSubmit={handleSubmit(formSubmit)}>
      <h1>パスワードリセット</h1>
      <p className="text-muted">新しいパスワードを入力してください。</p>
      <Field component={renderInput} label="パスワード" name="password" type="password" icon="cil-lock-locked" />
      <Field component={renderInput} label="パスワード (確認用)" name="password_confirmation" type="password" icon="cil-lock-locked" />
      <CRow>
        <CCol xs="6">
          <CButton color="primary" disabled={submitting} type="submit">変更</CButton>
        </CCol>
      </CRow>
    </CForm>
  );
}

const validate = (formValues) => {
  const errors = {};

  if (formValues.password !== formValues.password_confirmation) {
    errors.password_confirmation = 'パスワードと確認用パスワードが一致しません。';
  }

  if (formValues.password) {
    if (formValues.password.length < 12) {
      errors.password = 'パスワードは12文字以上で入力してください。';
    } else if (!formValues.password.match(/^(?=.*?[a-z])(?=.*?\d)(?=.*?[!-/:-@[-`{-~])[!-~]{12,}$/i)) {
      errors.password = 'パスワードには英数字と記号を含める必要があります。';
    }
  }

  return errors;
};

export default reduxForm({
  form: 'passwordResetForm',
  validate
})(PasswordResetForm);
