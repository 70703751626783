import shadankun, { deleteAuthHeaders, getCredentials, setAuthHeaders } from "../lib/apis/shadankun";
import { sessionService } from "redux-react-session";
import {
  SIGN_OUT,
  UNAUTHORIZED,
  TWO_FACTOR_AUTH_TOKEN
} from "./types";
import history from "../utils/history";
import _ from "lodash";
import { setUnauthorizedError } from "../services/unauthorizedErrorSlice";

// TOBE: テストを書きたい
export const signIn = (formValues, recaptcha) => async (dispatch) => {
  try {
    const response = await shadankun.post('/api/auth/sign_in', { ...formValues }, {validateStatus: (status) => {
        return status < 500;
      }});
    if (response.status < 300) {
      if (!response.data.two_factor_auth_token) {
        // 通常ログインor二段階認証通過
        const { data, current_customer } = response.data;
        data.current_customer = current_customer;

        setAuthHeaders(response.headers);
        await sessionService.saveSession(getCredentials(response.headers));
        await sessionService.saveUser(data);

        // エラー情報をクリア
        dispatch({ type: UNAUTHORIZED, payload: null }); // ログイン認証エラー
        dispatch(setUnauthorizedError(null)); // セッションタイムアウト

        history.push(`/console/${data.customer_code}/dashboard`);
      } else {
        // 二段階認証へ
        dispatch({type: TWO_FACTOR_AUTH_TOKEN, payload: response.data});
        history.push('/two_factor_auth');
      }
    } else {
      // 2FA認証の場合はrecaptcha.reset()しない
      recaptcha && recaptcha.reset();
      const { errors } = response.data;
      dispatch({ type: UNAUTHORIZED, payload: errors });
    }
  } catch (err) {
    // 2FA認証の場合はrecaptcha.reset()しない
    recaptcha && recaptcha.reset();
    console.error(err);
  }
};

export const signOut = () => async (dispatch) => {
  try {
    const currentSession = await sessionService.loadSession();
    await shadankun.delete('/api/auth/sign_out', {
      data: _.pick(currentSession, 'access-token', 'client', 'uid')
    });
    deleteAuthHeaders();
    sessionService.deleteSession();
    sessionService.deleteUser();
    // redux storeのデータクリア
    dispatch({ type: SIGN_OUT });
    history.push('/login');
  } catch (err) {
    console.error(err);
  }
};
