import '../scss/style.scss';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Router, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { PermissionProvider } from "../lib/permission";
import { rules } from "../lib/permission/config";
import Login from './Login';
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";
import PasswordResetMail from "./PasswordResetMail";
import PasswordReset from "./PasswordReset";
import TwoFactorAuth from "./two-factor-auth/TwoFactorAuth";
import Layout from "./Layout";
import history from '../utils/history';

const App = ({ authenticated, checked, unauthorized, user }) => {
  useEffect(() => {
    // リリース時に画面がクラッシュするのを防ぐため、ChunkLoadErrorが出たらreloadさせる
    window.addEventListener('error', e => {
      if (e.message.includes('ChunkLoadError')) {
        window.location.reload();
      }
    })
  }, []);

  return (
    <Router history={history}>
    {checked &&
      <PermissionProvider
        rules={rules}
        user={user}
        customer={user.current_customer}
        roles={user.role}
        permissions={[]}
      >
        <div>
          <Switch>
            <PublicRoute
              restricted={false}
              component={PasswordResetMail}
              path="/password"
              exact
            />
            <PublicRoute
              restricted={false}
              component={PasswordReset}
              path="/password/edit"
              exact
            />
            <PublicRoute
              restricted={false}
              component={TwoFactorAuth}
              path="/two_factor_auth"
              exact
            />
            <PublicRoute path="/login" component={Login} />
            <PrivateRoute
              path="/"
              component={Layout}
              authenticated={authenticated && !unauthorized}
            />
          </Switch>
        </div>
      </PermissionProvider>
    }
    </Router>
  );
}

const { bool } = PropTypes;

App.propTypes = {
  authenticated: bool.isRequired,
  checked: bool.isRequired
};

const mapStateToProps = ({ session, unauthorized_error }) => ({
  checked: session.checked,
  authenticated: session.authenticated,
  unauthorized: unauthorized_error.response,
  user: session.user
});

export default connect(mapStateToProps)(App);
