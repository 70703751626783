import ensureArray from "./ensureArray";
import roleHasPermission from "./roleHasPermission";

const rolesHavePermissions = (rules, roles, permissions, requiredPermissions = [], user, data) =>
  // for every required permission
  ensureArray(requiredPermissions).every(
    permission =>
      // check that some role has that permission
      roles.some(role =>
        roleHasPermission(rules, role, permission, user, data)
      ) ||
      // or this specific permission is granted
      Boolean(permissions.includes(permission)
        ?
        typeof rules[permission] === "function"
          ? // is abac permission
          (rules[permission](data, user))
          // permission doesn't need a predicate
          : true
        // permission not included
        : false
      )
  );

export default rolesHavePermissions;
