import React from "react";
import { Formik } from "formik";
import {
  CAlert,
  CCard,
  CCardHeader,
  CCardBody,
  CForm,
  CInputGroup,
  CInput,
  CInputGroupAppend,
  CButton,
  CContainer,
  CRow,
  CCol,
  CCardGroup,
} from "@coreui/react";
import { signIn } from "../../actions/auth";
import { useDispatch, useSelector } from "react-redux";

const TwoFactorAuth = () => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);

  return (
    <div className="c-app c-default-layout flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md="6">
            <CCardGroup>
              <CCard className="p-4">
                <CCardHeader>二段階認証</CCardHeader>
                <CCardBody>
                  <Formik
                    initialValues={{ otp_code: '' }}
                    onSubmit={(values, actions) => {
                      values.two_factor_auth_token = auth.two_factor_auth_token;
                      dispatch(signIn(values));
                    }}
                  >
                    {(props) => (
                      <CForm onSubmit={props.handleSubmit}>
                        <CAlert color="primary" show={auth.errors != null}>
                          {auth.errors}
                        </CAlert>
                        <CInputGroup>
                          <CInput
                            type="text"
                            name="otp_code"
                            autoComplete="認証コード"
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                            value={props.values.otp_code}
                          />
                          <CInputGroupAppend>
                            <CButton color="info" type="submit">
                              認証
                            </CButton>
                          </CInputGroupAppend>
                        </CInputGroup>
                      </CForm>
                    )}
                  </Formik>
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  );
}

export default TwoFactorAuth;
