import React from 'react';
import { useSelector, useDispatch } from "react-redux";
import {
  CBreadcrumbRouter,
  CHeader,
  CHeaderBrand,
  CHeaderNav,
  CLink,
  CSubheader,
  CToggler
} from '@coreui/react';
import CIcon from "@coreui/icons-react";
import HeaderDropdownAccount from "./HeaderDropdownAccount";
import { usePermission } from "../lib/permission";
import { selectAppConfigs, setAppConfigs } from "../services/appConfigSlice";

// routes config
import routes from '../config/routes';
import HeaderDropdownCustomer from "./HeaderDropdownCustomer";
import HeaderDropdownHelp from "./HeaderDropdownHelp";

const Header = () => {
  const dispatch = useDispatch();
  const appConfigs = useSelector(selectAppConfigs);
  const { currentUser, currentCustomer } = usePermission();
  const toggleSidebar = () => {
    const val = (appConfigs.sidebarShow === 'responsive') ? true : 'responsive';
    dispatch(setAppConfigs({ sidebarShow: val }));
  };

  return (
    <CHeader withSubheader>
      <div className="d-lg-none d-flex justify-content-center" style={{width: '100%', backgroundColor: '#3c4b64'}}>
        <CToggler
          inHeader
          onClick={toggleSidebar}
          title="Toggle Side Bar"
          style={{ color: "white" }}
        >
          <CIcon name="cil-menu" size="lg" />
        </CToggler>
        <CHeaderBrand to="/">
          <CIcon
            className="c-header-brand-full"
            src="/server_type.png"
            height="35"
          />
        </CHeaderBrand>
      </div>
      <HeaderDropdownCustomer user={currentUser} currentCustomer={currentCustomer} />

      <CHeaderNav className="ml-auto mr-4">
        <CLink className="c-header-nav-link" to="/notifications">
          <CIcon name="cil-bell" />
        </CLink>
        <HeaderDropdownHelp />
        <HeaderDropdownAccount user={currentUser} />
      </CHeaderNav>

      <CSubheader className="px-3 justify-content-between">
        <CBreadcrumbRouter
          className="border-0 c-subheader-nav m-0 px-3"
          routes={routes}
        />
      </CSubheader>
    </CHeader>
  );
};

export default Header;
