import React from 'react';
import { sessionService } from 'redux-react-session';
import { apiSlice } from "../services/apiSlice";
import {
  CHeaderNavItem,
  CHeaderNav
} from "@coreui/react";
import Select from "react-select";
import { useHistory } from "react-router-dom";
import { useGetCustomersQuery } from "../services/customer";

const HeaderDropdownCustomer = ({ user, currentCustomer }) => {
  const { data: customers, isLoading } = useGetCustomersQuery();
  const { push } = useHistory();

  const setCurrentCustomer = (customer) => {
    user.current_customer = customer;
    sessionService.saveUser({ ...user }).then(() => {
      // 顧客コードの切り替わり時に顧客別表示用データをクリア
      apiSlice.util.invalidateTags([
        { type: 'AttackLogs', id: 'LIST' },
        { type: 'SrvWafConfig', id: 'LIST' },
        { type: 'MonitoringAgents', id: 'LIST' },
        { type: 'PromptReports', id: 'LIST' },
        { type: 'SumDailyLogs', id: 'LIST' },
      ]);
      push(`/console/${user.current_customer.customer_code}/dashboard`);
    });
  };

  const customer_options = customers?.map(data => ({ value: data.id, label: `${data.name} (契約ID: ${data.customer_code})` }));
  const renderDropdownMenu = (customer) => (
    <div className="mx-2 my-2" style={{width: '40%'}}>
      <Select
        options={customer_options}
        value={({value: customer.id, label: `${customer.name} (${customer.customer_code})`})}
        onChange={(selectedOpt, _) => {
          let customer = customers.find((data) => { return data.id === selectedOpt.value});
          setCurrentCustomer(customer);
        }}
      />
    </div>
  );

  const renderNavItem = (customer) => (
    <CHeaderNav className="d-md-down-none mr-auto">
      <CHeaderNavItem className="px-3">
        {customer.name}
      </CHeaderNavItem>
    </CHeaderNav>
  );

  if (isLoading || !currentCustomer) {
    return null;
  }
  return customer_options.length > 1 ? renderDropdownMenu(currentCustomer) : renderNavItem(currentCustomer);
}

export default HeaderDropdownCustomer;
