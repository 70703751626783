import React from 'react';
import { connect } from 'react-redux';
import {
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { signOut } from "../actions/auth";
import { AllowedTo } from '../lib/permission';
import { Permission } from '../lib/permission/config';

const HeaderDropdownAccount = ({ signOut, user }) => (
  <CDropdown
    className="c-header-nav-items mx-2"
    direction="down"
  >
    <CDropdownToggle className="c-header-nav-link" caret={true}>
      {user.name}
    </CDropdownToggle>
    <CDropdownMenu className="pt-0" placement="bottom-end">
      <CDropdownItem
        header
        tag="div"
        color="light"
        className="text-center"
      >
        <strong>アカウント設定</strong>
      </CDropdownItem>
      <CDropdownItem to="/profile">
        <CIcon name="cil-user" />&nbsp;プロフィール情報
      </CDropdownItem>
      <CDropdownItem to="/users/password/edit">
        <CIcon name="cil-lock-locked" />&nbsp;パスワード変更
      </CDropdownItem>
      <AllowedTo perform={Permission.ENABLE_OWNER_SCOPE}>
        <CDropdownItem to="/billing_info">
          <CIcon name="cil-credit-card" />
          &nbsp;請求先情報
        </CDropdownItem>
        <CDropdownItem to="/licensing_info">
          <CIcon name="cil-file" />
          &nbsp;ライセンス情報
        </CDropdownItem>
      </AllowedTo>
      <CDropdownItem divider />
      <CDropdownItem onClick={() => signOut()}>
        <CIcon name="cil-account-logout" />&nbsp;ログアウト
      </CDropdownItem>
    </CDropdownMenu>
  </CDropdown>
);

export default connect(null, { signOut })(HeaderDropdownAccount);
