import React, { createContext, useContext } from "react";
import ensureArray from "./utils/ensureArray";
import rolesHavePermissions from "./utils/rolesHavePermissions";

const create = () => {
  const PermissionContextDefaults = {
    userHasPermissions: () => {
      console.error(
        `Can't call userHasPermission, wrap your app with an <PermissionProvide />.`,
      );
      return false;
    },
  };

  const PermissionContext = createContext(PermissionContextDefaults);

  const PermissionProvider = ({
                          children,
                          rules,
                          roles = [],
                          permissions = [],
                          user,
                          customer,
                        }) => {
    const userHasPermissions = (requiredPermissions, data) =>
      rolesHavePermissions(rules, ensureArray(roles), permissions, requiredPermissions, user, data);
    if (customer && user) {
      // オーナーユーザーかどうかのフラグを設定。
      user.isOwner = (customer.owner_id === user.id);
      // グループ管理者かどうかのフラグを設定。
      user.isGroupAdmin = !!(user.user_group_membership && user.user_group_membership.user_type === 'admin');
    }

    return (
      <PermissionContext.Provider value={{ userHasPermissions, currentUser: user, currentCustomer: customer }}>
        {children}
      </PermissionContext.Provider>
    );
  };

  const AllowedTo =  ({
    perform = [],
    children,
    yes: Yes = () => <React.Fragment>{children}</React.Fragment>,
    no: No = () => null,
    data
  }) => {
    const ctx = usePermission();

    if (ctx === PermissionContextDefaults) {
      console.error(
        `Can't render <AllowedTo />, wrap your app with an <PermissionProvider />.`,
      );
      return null;
    }

    return ctx.userHasPermissions(ensureArray(perform), data) ? (
      <Yes />
    ) : (
      <No />
    );
  };

  const NotAllowedTo = ({
    children,
    yes = () => <React.Fragment>{children}</React.Fragment>,
    no,
    ...props
  }) => (
    <AllowedTo no={yes} yes={no} {...props} />
  );

  const secured = ({
    permissions,
    noAccess: No,
    mapPropsToData
  }) => (Component) =>
    Object.assign(
      class SecuredComponent extends React.Component {
        render() {
          const data = mapPropsToData && mapPropsToData(this.props);
          const C = Component;

          return (
            <AllowedTo
              perform={permissions}
              no={No && (() => <No {...this.props} />)}
              yes={() => <C {...this.props} />}
              data={data}
            />
          )
        }
      },
      Component
    );

  const usePermission = () => useContext(PermissionContext);

  return {
    PermissionProvider,
    AllowedTo,
    PermissionContext,
    PermissionContextDefaults,
    NotAllowedTo,
    secured,
    usePermission,
  };
};

export default create;
