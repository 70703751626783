import evaluateRule from "./evaluateRule";

const roleHasPermission = (rules, role, permission, user, data) => {
  if (!(role in rules)) {
    // no rules defined for role
    return false;
  }

  // rule for the provided permission for the provided role
  // true, false or (data, user) => boolean
  const rule = rules[role][permission];

  return evaluateRule(rule, user, data);
};

export default roleHasPermission;
