import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  created_at_start: '',
  created_at_end: '',
  user_name: ''
};

const auditFiltersSlice = createSlice({
  name: 'auditFilters',
  initialState,
  reducers: {
    setAuditFilter(state, action) {
      return action.payload;
    },
    resetAuditFilters(state) {
      return initialState;
    }
  }
});

export const { setAuditFilter, resetAuditFilters } = auditFiltersSlice.actions;
export const selectAuditFilter = (state) => state.audit_filters;

export default auditFiltersSlice.reducer;
