import React from 'react';
import Sidebar from "./Sidebar";
import Footer from "./Footer";
import Header from "./Header";
import Content from "./Content";
import ErrorToast from "./ErrorToast";
import { useSelector } from "react-redux";


const Layout = () => {
  const isToastShow = useSelector((store) => store.error_toast.show);

  return (
    <>
      <div className='c-app c-default-layout'>
        <Sidebar/>
        <div className="c-wrapper">
          <Header/>
          <div children="c-body">
            <Content/>
          </div>
          <Footer/>
        </div>
      </div>
      {isToastShow && <ErrorToast />}
    </>
  )
};

export default Layout;
