import create from "./create";

export const {
  AllowedTo,
  secured,
  NotAllowedTo,
  PermissionContextDefaults,
  PermissionProvider,
  PermissionContext,
  usePermission
} = create();
