import { createSlice } from "@reduxjs/toolkit";

const errorToastSlice = createSlice({
  name: 'errorToast',
  initialState: {
    show: false,
    header: null,
    body: null
  },
  reducers: {
    showErrorToast: (state, action) => {
      return {
        show: true,
        header: action.payload.header,
        body: action.payload.body
      };
    },
    hideErrorToast: () => {
      return {
        show: false,
        header: null,
        body: null
      };
    },
  },
});

export const { showErrorToast, hideErrorToast } = errorToastSlice.actions;
export default errorToastSlice.reducer;
