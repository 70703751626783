import axios from "axios";
import _ from "lodash";

const httpClient = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
  withCredentials: true,
  timeout: 10000,
});

httpClient.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return error.response;
  }
);

const authHeaderKeys = [
  "access-token",
  "token-type",
  "client",
  "expiry",
  "uid",
];

export const verifyToken = async (params) => {
  try {
    const response = await httpClient.get("/api/auth/validate_token", {
      params: params,
    });
    if (!response.data.success) {
      return false;
    }
    setAuthHeaders(response.headers);
    return true;
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const getAuthHeaders = () => {
  const headers = [];
  authHeaderKeys.forEach((key) => {
    headers[key] = httpClient.defaults.headers.common[key];
  });
  return headers;
};

export const setAuthHeaders = (headers) => {
  authHeaderKeys.forEach((key) => {
    httpClient.defaults.headers.common[key] = headers[key];
  });
};

export const deleteAuthHeaders = () => {
  authHeaderKeys.forEach((key) => {
    delete httpClient.defaults.headers.common[key];
  });
};

export const getCredentials = (headers) => {
  return _.pick(headers, authHeaderKeys);
};

export default httpClient;
