import { UNAUTHORIZED, TWO_FACTOR_AUTH_TOKEN } from "../actions/types";

const INITIAL_STATE = {
  errors: null
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    // TODO: errors周りはリファクタリングして要撤去
    case TWO_FACTOR_AUTH_TOKEN:
      return { ...state, errors: null, ...action.payload };
    case UNAUTHORIZED:
      return { ...state, errors: action.payload };
    default:
      return state;
  }
};

export default reducer;
