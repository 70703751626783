export const Role = {
  ADMIN: "admin",
  OPERATOR: "operator",
  OPERATOR_READONLY: "operator_readonly",
  AGENT: "agent",
  AGENT_READONLY: "agent_readonly",
  GENERAL: "general"
};

export const Permission = {
  ENABLE_ADMIN_SCOPE: "admin_scope:enable",
  ENABLE_AGENT_SCOPE: "agent_scope:enable",
  ENABLE_OWNER_SCOPE: "owner_scope:enable",
  ENABLE_MENU_GLOBAL: "menu_global:enable",

  READ_API_KEY: "api_key:read",
  CREATE_API_KEY: "api_key:create",
  EDIT_API_KEY: "api_key:edit",
  DELETE_API_KEY: "api_key:delete",

  READ_BILLING_DESTINATION: "billing_destination:read",
  CREATE_BILLING_DESTINATION: "billing_destination:create",
  EDIT_BILLING_DESTINATION: "billing_destination:edit",
  DELETE_BILLING_DESTINATION: "billing_destination:delete",

  READ_CUSTOMER: "customer:read",
  CREATE_CUSTOMER: "customer:create",
  EDIT_CUSTOMER: "customer:edit",
  DELETE_CUSTOMER: "customer:delete",

  READ_DISTRIBUTOR: "distributor:read",
  CREATE_DISTRIBUTOR: "distributor:create",
  EDIT_DISTRIBUTOR: "distributor:edit",
  DELETE_DISTRIBUTOR: "distributor:delete",

  READ_INFORMATION: "information:read",
  CREATE_INFORMATION: "information:create",
  EDIT_INFORMATION: "information:edit",
  DELETE_INFORMATION: "information:delete",

  CREATE_INQUIRY: "inquiry:create",

  READ_LICENSE: "license:read",
  CREATE_LICENSE: "license:create",
  EDIT_LICENSE: "license:edit",
  DELETE_LICENSE: "license:delete",

  READ_MONITORING_AGENT: "monitoring_agent:read",
  CREATE_MONITORING_AGENT: "monitoring_agent:create",
  EDIT_MONITORING_AGENT: "monitoring_agent:edit",
  DELETE_MONITORING_AGENT: "monitoring_agent:delete",

  READ_PRICE_PLAN: "price_plan:read",
  CREATE_PRICE_PLAN: "price_plan:create",
  EDIT_PRICE_PLAN: "price_plan:edit",
  DELETE_PRICE_PLAN: "price_plan:delete",

  READ_SRV_PLATFORM: "srv_platform:read",
  CREATE_SRV_PLATFORM: "srv_platform:create",
  EDIT_SRV_PLATFORM: "srv_platform:edit",
  DELETE_SRV_PLATFORM: "srv_platform:delete",

  READ_SRV_PLATFORM_SRV: "srvpf_servers:read",
  CREATE_SRV_PLATFORM_SRV: "srvpf_servers:create",
  EDIT_SRV_PLATFORM_SRV: "srvpf_servers:edit",
  DELETE_SRV_PLATFORM_SRV: "srvpf_servers:delete",

  READ_SRV_WAF_CONFIG: "srv_waf_config:read",
  CREATE_SRV_WAF_CONFIG: "srv_waf_config:create",
  EDIT_SRV_WAF_CONFIG: "srv_waf_config:edit",
  DELETE_SRV_WAF_CONFIG: "srv_waf_config:delete",

  EDIT_SRV_WAF_CONFIG_ACL: "srv_waf_config_acl:edit",

  READ_USER: "user:read",
  CREATE_USER: "user:create",
  EDIT_USER: "user:edit",
  DELETE_USER: "user:delete",

  READ_USER_GROUP: "user_group:read",
  CREATE_USER_GROUP: "user_group:create",
  EDIT_USER_GROUP: "user_group:edit",
  DELETE_USER_GROUP: "user_group:delete",

  READ_WAF_RULE: "waf_rule:read",
  IMPORT_WAF_RULE: "waf_rule:import",
  DELIVER_WAF_RULE: "waf_rule:deliver",

  READ_WAF_RULE_HISTORY: "waf_rule_history:read",
};



export const rules = {
  [Role.ADMIN]: {
    [Permission.ENABLE_ADMIN_SCOPE]: true,
    [Permission.ENABLE_AGENT_SCOPE]: true,
    [Permission.ENABLE_OWNER_SCOPE]: false,

    [Permission.READ_API_KEY]: true,
    [Permission.CREATE_API_KEY]: true,
    [Permission.EDIT_API_KEY]: true,
    [Permission.DELETE_API_KEY]: true,

    [Permission.READ_BILLING_DESTINATION]: true,
    [Permission.CREATE_BILLING_DESTINATION]: true,
    [Permission.EDIT_BILLING_DESTINATION]: true,
    [Permission.DELETE_BILLING_DESTINATION]: true,

    [Permission.READ_CUSTOMER]: true,
    [Permission.CREATE_CUSTOMER]: true,
    [Permission.EDIT_CUSTOMER]: true,
    [Permission.DELETE_CUSTOMER]: true,

    [Permission.READ_DISTRIBUTOR]: true,
    [Permission.CREATE_DISTRIBUTOR]: true,
    [Permission.EDIT_DISTRIBUTOR]: true,
    [Permission.DELETE_DISTRIBUTOR]: true,

    [Permission.READ_INFORMATION]: true,
    [Permission.CREATE_INFORMATION]: true,
    [Permission.EDIT_INFORMATION]: true,
    [Permission.DELETE_INFORMATION]: true,

    [Permission.CREATE_INQUIRY]: true,

    [Permission.READ_LICENSE]: true,
    [Permission.CREATE_LICENSE]: true,
    [Permission.EDIT_LICENSE]: true,
    [Permission.DELETE_LICENSE]: true,

    [Permission.READ_MONITORING_AGENT]: true,
    [Permission.CREATE_MONITORING_AGENT]: true,
    [Permission.EDIT_MONITORING_AGENT]: true,
    [Permission.DELETE_MONITORING_AGENT]: true,

    [Permission.READ_PRICE_PLAN]: true,
    [Permission.CREATE_PRICE_PLAN]: true,
    [Permission.EDIT_PRICE_PLAN]: true,
    [Permission.DELETE_PRICE_PLAN]: true,

    [Permission.READ_SRV_PLATFORM]: true,
    [Permission.CREATE_SRV_PLATFORM]: true,
    [Permission.EDIT_SRV_PLATFORM]: true,
    [Permission.DELETE_SRV_PLATFORM]: true,

    [Permission.READ_SRV_PLATFORM_SRV]: true,
    [Permission.CREATE_SRV_PLATFORM_SRV]: true,
    [Permission.EDIT_SRV_PLATFORM_SRV]: true,
    [Permission.DELETE_SRV_PLATFORM_SRV]: true,

    [Permission.READ_SRV_WAF_CONFIG]: true,
    [Permission.CREATE_SRV_WAF_CONFIG]: true,
    [Permission.EDIT_SRV_WAF_CONFIG]: true,
    [Permission.DELETE_SRV_WAF_CONFIG]: true,

    [Permission.EDIT_SRV_WAF_CONFIG_ACL]: (srv_waf_config, currentUser) => {
      if (!srv_waf_config || !currentUser) return false;
      return srv_waf_config.waf_config_type === 'private_config';
    },

    [Permission.READ_USER]: true,
    [Permission.CREATE_USER]: true,
    [Permission.EDIT_USER]: true,
    [Permission.DELETE_USER]: true,

    [Permission.READ_USER_GROUP]: true,
    [Permission.CREATE_USER_GROUP]: true,
    [Permission.EDIT_USER_GROUP]: true,
    [Permission.DELETE_USER_GROUP]: true,

    [Permission.READ_WAF_RULE]: true,
    [Permission.IMPORT_WAF_RULE]: true,
    [Permission.DELIVER_WAF_RULE]: true,
    [Permission.READ_WAF_RULE_HISTORY]: true,
  },


  [Role.OPERATOR]: {
    [Permission.ENABLE_ADMIN_SCOPE]: true,
    [Permission.ENABLE_AGENT_SCOPE]: true,
    [Permission.ENABLE_OWNER_SCOPE]: false,

    [Permission.READ_API_KEY]: true,
    [Permission.CREATE_API_KEY]: true,
    [Permission.EDIT_API_KEY]: true,
    [Permission.DELETE_API_KEY]: false,

    [Permission.READ_BILLING_DESTINATION]: true,
    [Permission.CREATE_BILLING_DESTINATION]: true,
    [Permission.EDIT_BILLING_DESTINATION]: true,
    [Permission.DELETE_BILLING_DESTINATION]: false,

    [Permission.READ_CUSTOMER]: true,
    [Permission.CREATE_CUSTOMER]: true,
    [Permission.EDIT_CUSTOMER]: true,
    [Permission.DELETE_CUSTOMER]: false,

    [Permission.READ_DISTRIBUTOR]: true,
    [Permission.CREATE_DISTRIBUTOR]: true,
    [Permission.EDIT_DISTRIBUTOR]: true,
    [Permission.DELETE_DISTRIBUTOR]: false,

    [Permission.READ_INFORMATION]: true,
    [Permission.CREATE_INFORMATION]: true,
    [Permission.EDIT_INFORMATION]: true,
    [Permission.DELETE_INFORMATION]: false,

    [Permission.CREATE_INQUIRY]: false,

    [Permission.READ_LICENSE]: true,
    [Permission.CREATE_LICENSE]: true,
    [Permission.EDIT_LICENSE]: true,
    [Permission.DELETE_LICENSE]: false,

    [Permission.READ_MONITORING_AGENT]: true,
    [Permission.CREATE_MONITORING_AGENT]: true,
    [Permission.EDIT_MONITORING_AGENT]: true,
    [Permission.DELETE_MONITORING_AGENT]: false,

    [Permission.READ_PRICE_PLAN]: true,
    [Permission.CREATE_PRICE_PLAN]: true,
    [Permission.EDIT_PRICE_PLAN]: true,
    [Permission.DELETE_PRICE_PLAN]: false,

    [Permission.READ_SRV_PLATFORM]: true,
    [Permission.CREATE_SRV_PLATFORM]: true,
    [Permission.EDIT_SRV_PLATFORM]: true,
    [Permission.DELETE_SRV_PLATFORM]: false,

    [Permission.READ_SRV_PLATFORM_SRV]: true,
    [Permission.CREATE_SRV_PLATFORM_SRV]: true,
    [Permission.EDIT_SRV_PLATFORM_SRV]: true,
    [Permission.DELETE_SRV_PLATFORM_SRV]: false,

    [Permission.READ_SRV_WAF_CONFIG]: true,
    [Permission.CREATE_SRV_WAF_CONFIG]: true,
    [Permission.EDIT_SRV_WAF_CONFIG]: true,
    [Permission.DELETE_SRV_WAF_CONFIG]: true,

    [Permission.EDIT_SRV_WAF_CONFIG_ACL]: (srv_waf_config, currentUser) => {
      if (!srv_waf_config || !currentUser) return false;
      return srv_waf_config.waf_config_type === 'private_config';
    },

    [Permission.READ_USER]: true,
    [Permission.CREATE_USER]: true,
    [Permission.EDIT_USER]: true,
    [Permission.DELETE_USER]: true,

    [Permission.READ_USER_GROUP]: true,
    [Permission.CREATE_USER_GROUP]: true,
    [Permission.EDIT_USER_GROUP]: true,
    [Permission.DELETE_USER_GROUP]: true,

    [Permission.READ_WAF_RULE]: false,
    [Permission.IMPORT_WAF_RULE]: false,
    [Permission.DELIVER_WAF_RULE]: false,
    [Permission.READ_WAF_RULE_HISTORY]: true,
  },


  [Role.OPERATOR_READONLY]: {
    [Permission.ENABLE_ADMIN_SCOPE]: true,
    [Permission.ENABLE_AGENT_SCOPE]: true,
    [Permission.ENABLE_OWNER_SCOPE]: false,

    [Permission.READ_API_KEY]: true,
    [Permission.CREATE_API_KEY]: false,
    [Permission.EDIT_API_KEY]: false,
    [Permission.DELETE_API_KEY]: false,

    [Permission.READ_BILLING_DESTINATION]: true,
    [Permission.CREATE_BILLING_DESTINATION]: false,
    [Permission.EDIT_BILLING_DESTINATION]: false,
    [Permission.DELETE_BILLING_DESTINATION]: false,

    [Permission.READ_CUSTOMER]: true,
    [Permission.CREATE_CUSTOMER]: false,
    [Permission.EDIT_CUSTOMER]: false,
    [Permission.DELETE_CUSTOMER]: false,

    [Permission.READ_DISTRIBUTOR]: true,
    [Permission.CREATE_DISTRIBUTOR]: false,
    [Permission.EDIT_DISTRIBUTOR]: false,
    [Permission.DELETE_DISTRIBUTOR]: false,

    [Permission.READ_INFORMATION]: true,
    [Permission.CREATE_INFORMATION]: false,
    [Permission.EDIT_INFORMATION]: false,
    [Permission.DELETE_INFORMATION]: false,

    [Permission.CREATE_INQUIRY]: false,

    [Permission.READ_LICENSE]: true,
    [Permission.CREATE_LICENSE]: false,
    [Permission.EDIT_LICENSE]: false,
    [Permission.DELETE_LICENSE]: false,

    [Permission.READ_MONITORING_AGENT]: true,
    [Permission.CREATE_MONITORING_AGENT]: false,
    [Permission.EDIT_MONITORING_AGENT]: false,
    [Permission.DELETE_MONITORING_AGENT]: false,

    [Permission.READ_PRICE_PLAN]: true,
    [Permission.CREATE_PRICE_PLAN]: false,
    [Permission.EDIT_PRICE_PLAN]: false,
    [Permission.DELETE_PRICE_PLAN]: false,

    [Permission.READ_SRV_PLATFORM]: true,
    [Permission.CREATE_SRV_PLATFORM]: false,
    [Permission.EDIT_SRV_PLATFORM]: false,
    [Permission.DELETE_SRV_PLATFORM]: false,

    [Permission.READ_SRV_PLATFORM_SRV]: true,
    [Permission.CREATE_SRV_PLATFORM_SRV]: false,
    [Permission.EDIT_SRV_PLATFORM_SRV]: false,
    [Permission.DELETE_SRV_PLATFORM_SRV]: false,

    [Permission.READ_SRV_WAF_CONFIG]: true,
    [Permission.CREATE_SRV_WAF_CONFIG]: false,
    [Permission.EDIT_SRV_WAF_CONFIG]: false,
    [Permission.DELETE_SRV_WAF_CONFIG]: false,

    [Permission.EDIT_SRV_WAF_CONFIG_ACL]: false,

    [Permission.READ_USER]: true,
    [Permission.CREATE_USER]: false,
    [Permission.EDIT_USER]: (user, currentUser) => {
      if (!user || !currentUser) return false;
      return currentUser.id === user.id;
    },
    [Permission.DELETE_USER]: false,

    [Permission.READ_USER_GROUP]: true,
    [Permission.CREATE_USER_GROUP]: false,
    [Permission.EDIT_USER_GROUP]: false,
    [Permission.DELETE_USER_GROUP]: false,

    [Permission.READ_WAF_RULE]: false,
    [Permission.IMPORT_WAF_RULE]: false,
    [Permission.DELIVER_WAF_RULE]: false,
    [Permission.READ_WAF_RULE_HISTORY]: true,
  },


  [Role.AGENT]: {
    [Permission.ENABLE_ADMIN_SCOPE]: false,
    [Permission.ENABLE_AGENT_SCOPE]: true,
    [Permission.ENABLE_OWNER_SCOPE]: false,

    [Permission.READ_API_KEY]: true,
    [Permission.CREATE_API_KEY]: false,
    [Permission.EDIT_API_KEY]: false,
    [Permission.DELETE_API_KEY]: false,

    [Permission.READ_BILLING_DESTINATION]: false,
    [Permission.CREATE_BILLING_DESTINATION]: false,
    [Permission.EDIT_BILLING_DESTINATION]: false,
    [Permission.DELETE_BILLING_DESTINATION]: false,

    [Permission.READ_CUSTOMER]: true,
    [Permission.CREATE_CUSTOMER]: true,
    [Permission.EDIT_CUSTOMER]: true,
    [Permission.DELETE_CUSTOMER]: false,

    [Permission.READ_DISTRIBUTOR]: false,
    [Permission.CREATE_DISTRIBUTOR]: false,
    [Permission.EDIT_DISTRIBUTOR]: false,
    [Permission.DELETE_DISTRIBUTOR]: false,

    [Permission.READ_INFORMATION]: false,
    [Permission.CREATE_INFORMATION]: false,
    [Permission.EDIT_INFORMATION]: false,
    [Permission.DELETE_INFORMATION]: false,

    [Permission.CREATE_INQUIRY]: true,

    [Permission.READ_LICENSE]: (data = {}, currentUser) => {
      if (!currentUser || !currentUser.delegated_permissions) return false;
      return (currentUser.delegated_permissions['license'] === 'full');
    },
    [Permission.CREATE_LICENSE]: (data = {}, currentUser) => {
      if (!currentUser || !currentUser.delegated_permissions) return false;
      return (currentUser.delegated_permissions['license'] === 'full');
    },
    [Permission.EDIT_LICENSE]: (data = {}, currentUser) => {
      if (!currentUser || !currentUser.delegated_permissions) return false;
      return (currentUser.delegated_permissions['license'] === 'full');
    },
    [Permission.DELETE_LICENSE]: false,

    [Permission.READ_MONITORING_AGENT]: true,
    [Permission.CREATE_MONITORING_AGENT]: true,
    [Permission.EDIT_MONITORING_AGENT]: true,
    [Permission.DELETE_MONITORING_AGENT]: false,

    [Permission.READ_PRICE_PLAN]: false,
    [Permission.CREATE_PRICE_PLAN]: false,
    [Permission.EDIT_PRICE_PLAN]: false,
    [Permission.DELETE_PRICE_PLAN]: false,

    [Permission.READ_SRV_PLATFORM]: false,
    [Permission.CREATE_SRV_PLATFORM]: false,
    [Permission.EDIT_SRV_PLATFORM]: false,
    [Permission.DELETE_SRV_PLATFORM]: false,

    [Permission.READ_SRV_PLATFORM_SRV]: false,
    [Permission.CREATE_SRV_PLATFORM_SRV]: false,
    [Permission.EDIT_SRV_PLATFORM_SRV]: false,
    [Permission.DELETE_SRV_PLATFORM_SRV]: false,

    [Permission.READ_SRV_WAF_CONFIG]: true,
    [Permission.CREATE_SRV_WAF_CONFIG]: true,
    [Permission.EDIT_SRV_WAF_CONFIG]: true,
    [Permission.DELETE_SRV_WAF_CONFIG]: true,

    [Permission.EDIT_SRV_WAF_CONFIG_ACL]: (srv_waf_config, currentUser) => {
      if (!srv_waf_config || !currentUser) return false;
      return srv_waf_config.waf_config_type === 'private_config';
    },

    [Permission.READ_USER]: true,
    [Permission.CREATE_USER]: true,
    [Permission.EDIT_USER]: true,
    [Permission.DELETE_USER]: true,

    [Permission.READ_USER_GROUP]: true,
    [Permission.CREATE_USER_GROUP]: true,
    [Permission.EDIT_USER_GROUP]: true,
    [Permission.DELETE_USER_GROUP]: true,

    [Permission.READ_WAF_RULE]: false,
    [Permission.IMPORT_WAF_RULE]: false,
    [Permission.DELIVER_WAF_RULE]: false,
    [Permission.READ_WAF_RULE_HISTORY]: false,
  },

  [Role.AGENT_READONLY]: {
    [Permission.ENABLE_ADMIN_SCOPE]: false,
    [Permission.ENABLE_AGENT_SCOPE]: true,
    [Permission.ENABLE_OWNER_SCOPE]: false,

    [Permission.READ_API_KEY]: true,
    [Permission.CREATE_API_KEY]: false,
    [Permission.EDIT_API_KEY]: false,
    [Permission.DELETE_API_KEY]: false,

    [Permission.READ_BILLING_DESTINATION]: false,
    [Permission.CREATE_BILLING_DESTINATION]: false,
    [Permission.EDIT_BILLING_DESTINATION]: false,
    [Permission.DELETE_BILLING_DESTINATION]: false,

    [Permission.READ_CUSTOMER]: true,
    [Permission.CREATE_CUSTOMER]: false,
    [Permission.EDIT_CUSTOMER]: false,
    [Permission.DELETE_CUSTOMER]: false,

    [Permission.READ_DISTRIBUTOR]: false,
    [Permission.CREATE_DISTRIBUTOR]: false,
    [Permission.EDIT_DISTRIBUTOR]: false,
    [Permission.DELETE_DISTRIBUTOR]: false,

    [Permission.READ_INFORMATION]: false,
    [Permission.CREATE_INFORMATION]: false,
    [Permission.EDIT_INFORMATION]: false,
    [Permission.DELETE_INFORMATION]: false,

    [Permission.CREATE_INQUIRY]: false,

    [Permission.READ_LICENSE]: (data = {}, currentUser) => {
      if (!currentUser || !currentUser.delegated_permissions) return false;
      return (currentUser.delegated_permissions['license'] === 'full');
    },
    [Permission.CREATE_LICENSE]: false,
    [Permission.EDIT_LICENSE]: false,
    [Permission.DELETE_LICENSE]: false,

    [Permission.READ_MONITORING_AGENT]: true,
    [Permission.CREATE_MONITORING_AGENT]: false,
    [Permission.EDIT_MONITORING_AGENT]: false,
    [Permission.DELETE_MONITORING_AGENT]: false,

    [Permission.READ_PRICE_PLAN]: false,
    [Permission.CREATE_PRICE_PLAN]: false,
    [Permission.EDIT_PRICE_PLAN]: false,
    [Permission.DELETE_PRICE_PLAN]: false,

    [Permission.READ_SRV_PLATFORM]: false,
    [Permission.CREATE_SRV_PLATFORM]: false,
    [Permission.EDIT_SRV_PLATFORM]: false,
    [Permission.DELETE_SRV_PLATFORM]: false,

    [Permission.READ_SRV_PLATFORM_SRV]: false,
    [Permission.CREATE_SRV_PLATFORM_SRV]: false,
    [Permission.EDIT_SRV_PLATFORM_SRV]: false,
    [Permission.DELETE_SRV_PLATFORM_SRV]: false,

    [Permission.READ_SRV_WAF_CONFIG]: true,
    [Permission.CREATE_SRV_WAF_CONFIG]: false,
    [Permission.EDIT_SRV_WAF_CONFIG]: false,
    [Permission.DELETE_SRV_WAF_CONFIG]: false,

    [Permission.EDIT_SRV_WAF_CONFIG_ACL]: false,

    [Permission.READ_USER]: true,
    [Permission.CREATE_USER]: false,
    [Permission.EDIT_USER]: (user, currentUser) => {
      if (!user || !currentUser) return false;
      return currentUser.id === user.id;
    },
    [Permission.DELETE_USER]: false,

    [Permission.READ_USER_GROUP]: true,
    [Permission.CREATE_USER_GROUP]: false,
    [Permission.EDIT_USER_GROUP]: false,
    [Permission.DELETE_USER_GROUP]: false,

    [Permission.READ_WAF_RULE]: false,
    [Permission.IMPORT_WAF_RULE]: false,
    [Permission.DELIVER_WAF_RULE]: false,
    [Permission.READ_WAF_RULE_HISTORY]: false,
  },

  [Role.GENERAL]: {
    [Permission.ENABLE_ADMIN_SCOPE]: false,
    [Permission.ENABLE_AGENT_SCOPE]: false,
    [Permission.ENABLE_OWNER_SCOPE]: (data = {}, currentUser) => {
      if (!currentUser) return false;
      // オーナーに指定されている or 「グループ管理者」の場合は許可。
      return currentUser.isOwner || currentUser.isGroupAdmin;
    },

    [Permission.READ_API_KEY]: (apiKey, currentUser) => {
      if (!currentUser) return false;
      // 「オーナー」の場合は許可。
      return currentUser.isOwner;
    },
    [Permission.CREATE_API_KEY]: false,
    [Permission.EDIT_API_KEY]: false,
    [Permission.DELETE_API_KEY]: false,

    [Permission.READ_BILLING_DESTINATION]: false,
    [Permission.CREATE_BILLING_DESTINATION]: false,
    [Permission.EDIT_BILLING_DESTINATION]: false,
    [Permission.DELETE_BILLING_DESTINATION]: false,

    [Permission.READ_CUSTOMER]: false,
    [Permission.CREATE_CUSTOMER]: false,
    [Permission.EDIT_CUSTOMER]: false,
    [Permission.DELETE_CUSTOMER]: false,

    [Permission.READ_DISTRIBUTOR]: false,
    [Permission.CREATE_DISTRIBUTOR]: false,
    [Permission.EDIT_DISTRIBUTOR]: false,
    [Permission.DELETE_DISTRIBUTOR]: false,

    [Permission.READ_INFORMATION]: true,
    [Permission.CREATE_INFORMATION]: false,
    [Permission.EDIT_INFORMATION]: false,
    [Permission.DELETE_INFORMATION]: false,

    [Permission.CREATE_INQUIRY]:  (inquiry, currentUser) => {
      if (!currentUser) return false;
      // 「オーナー」に指定されている or「グループ管理者」の場合は許可。
      return currentUser.isOwner || currentUser.isGroupAdmin;
    },

    [Permission.READ_LICENSE]: false,
    [Permission.CREATE_LICENSE]: false,
    [Permission.EDIT_LICENSE]: false,
    [Permission.DELETE_LICENSE]: false,

    [Permission.READ_MONITORING_AGENT]: true,
    [Permission.CREATE_MONITORING_AGENT]: true,
    [Permission.EDIT_MONITORING_AGENT]: (monitoring_agent, currentUser) => {
      if (!monitoring_agent || !currentUser) return false;
      // 「オーナー」に設定されていれば無条件で許可。
      if (currentUser.isOwner) return true;
      // 「共有範囲が個別」の場合はACLに応じて許可。
      if (monitoring_agent.waf_config_type === 'private_config') {
        const acl = monitoring_agent.srv_waf_config_acls.find((acl) => acl.user_id === currentUser.id);
        return acl && (acl.permission === 'full' || acl.permission === 'edit');
      }
      return currentUser.customer_id === monitoring_agent.customer_id;
    },
    [Permission.DELETE_MONITORING_AGENT]: (monitoring_agent, currentUser) => {
      if (!monitoring_agent || !currentUser) return false;
      // 「オーナー」に設定されていれば無条件で許可。
      if (currentUser.isOwner) return true;
      // 「共有範囲が個別」の場合はACLに応じて許可。
      if (monitoring_agent.waf_config_type === 'private_config') {
        const acl = monitoring_agent.srv_waf_config_acls.find((acl) => acl.user_id === currentUser.id);
        return acl && (acl.permission === 'full' || acl.permission === 'edit');
      }
      return currentUser.customer_id === monitoring_agent.customer_id;
    },

    [Permission.READ_PRICE_PLAN]: false,
    [Permission.CREATE_PRICE_PLAN]: false,
    [Permission.EDIT_PRICE_PLAN]: false,
    [Permission.DELETE_PRICE_PLAN]: false,

    [Permission.READ_SRV_PLATFORM]: false,
    [Permission.CREATE_SRV_PLATFORM]: false,
    [Permission.EDIT_SRV_PLATFORM]: false,
    [Permission.DELETE_SRV_PLATFORM]: false,

    [Permission.READ_SRV_PLATFORM_SRV]: false,
    [Permission.CREATE_SRV_PLATFORM_SRV]: false,
    [Permission.EDIT_SRV_PLATFORM_SRV]: false,
    [Permission.DELETE_SRV_PLATFORM_SRV]: false,

    [Permission.READ_SRV_WAF_CONFIG]: true,
    [Permission.CREATE_SRV_WAF_CONFIG]: (srv_waf_config, currentUser) => {
      if (!currentUser) return false;
      // 「オーナー」に指定されている or「グループ管理者」の場合は許可。
      return currentUser.isOwner || currentUser.isGroupAdmin;
    },
    [Permission.EDIT_SRV_WAF_CONFIG]: (srv_waf_config, currentUser) => {
      if (!srv_waf_config || !currentUser) return false;
      // 「オーナー」に設定されていれば無条件で許可。
      if (currentUser.isOwner) return true;
      // 「共有範囲が個別」の場合はACLに応じて許可。
      if (srv_waf_config.waf_config_type === 'private_config') {
        const acl = srv_waf_config.srv_waf_config_acls.find((acl) => acl.user_id === currentUser.id);
        return acl && (acl.permission === 'full' || acl.permission === 'edit');
      }
      return currentUser.customer_id === srv_waf_config.customer_id;
    },
    [Permission.DELETE_SRV_WAF_CONFIG]: (srv_waf_config, currentUser) => {
      if (!currentUser) return false;
      // 「オーナー」に設定されていれば無条件で許可。
      if (currentUser.isOwner) return true;
      // 「共有範囲が個別」の場合はACLに応じて許可。
      if (srv_waf_config.waf_config_type === 'private_config') {
        const acl = srv_waf_config.srv_waf_config_acls.find((acl) => acl.user_id === currentUser.id);
        return acl && acl.permission === 'full';
      }
      // 「共有範囲がグループ」の場合は「グループ管理者」の場合は許可。
      if (srv_waf_config.waf_config_type === 'group_config') {
        return currentUser.isGroupAdmin;
      }
      return false;
    },

    [Permission.EDIT_SRV_WAF_CONFIG_ACL]: (srv_waf_config, currentUser) => {
      if (!currentUser) return false;
      // 「共有範囲が個別」の場合はACLに応じて許可。
      if (srv_waf_config.waf_config_type === 'private_config') {
        // 「オーナー」に設定されていれば無条件で許可。
        if (currentUser.isOwner) return true;
        const acl = srv_waf_config.srv_waf_config_acls.find((acl) => acl.user_id === currentUser.id);
        return acl && acl.permission === 'full';
      }
      return false;
    },

    [Permission.READ_USER]: true,
    [Permission.CREATE_USER]: (user, currentUser) => {
      if (!currentUser) return false;
      // 「オーナー」に指定されている or「グループ管理者」の場合は許可。
      return currentUser.isOwner || currentUser.isGroupAdmin;
    },
    [Permission.EDIT_USER]: (user, currentUser) => {
      if (!user || !currentUser) return false;
      return currentUser.id === user.id ||
        // 「オーナー」に指定されている or 「グループ管理者」の場合は許可。
        currentUser.isOwner || currentUser.isGroupAdmin;
    },
    [Permission.DELETE_USER]: (user, currentUser) => {
      if (!user || !currentUser) return false;
      return currentUser.id === user.id ||
        // 「オーナー」に指定されている　or 「グループ管理者」の場合は許可。
        currentUser.isOwner || currentUser.isGroupAdmin;
    },

    [Permission.READ_USER_GROUP]: true,
    [Permission.CREATE_USER_GROUP]: (userGroup, currentUser) => {
      // 「オーナー」に指定されている場合は許可。
      return currentUser.isOwner;
    },
    [Permission.EDIT_USER_GROUP]: (userGroup, currentUser) => {
      if (!userGroup || !currentUser) return false;
      // 「オーナー」に指定されている場合は許可。
      return currentUser.isOwner ||
        //「グループ管理者」で自グループの場合は許可。
        (currentUser.isGroupAdmin && userGroup.id === currentUser.user_group_membership.user_group_id);
    },
    [Permission.DELETE_USER_GROUP]: (userGroup, currentUser) => {
      // 「オーナー」に指定されている場合は許可。
      return currentUser.isOwner;
    },
    [Permission.READ_WAF_RULE]: false,
    [Permission.IMPORT_WAF_RULE]: false,
    [Permission.DELIVER_WAF_RULE]: false,
    [Permission.READ_WAF_RULE_HISTORY]: false,
  },
};
