import React from 'react';
import { connect } from 'react-redux';
import {
  CCard,
  CCardBody,
  CCardFooter,
  CCardGroup,
  CCardHeader,
  CCol,
  CContainer,
  CRow
} from "@coreui/react";
import CIcon from '@coreui/icons-react';
import LoginForm from "./forms/LoginForm";
import { signIn } from "../actions/auth";

const Login = ({ errors, session_timeout, signIn }) => {
  const onSubmit = (formValues, recaptcha) => {
    signIn(formValues, recaptcha);
  };

  return (
    <div className="c-app c-default-layout flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md="6">
            <CCardGroup>
              <CCard>
                <CCardHeader className="text-center" style={{background: 'rgb(60, 75, 100)'}}>
                  <CIcon
                    src="/server_type.png"
                    height="35"
                  />
                </CCardHeader>
                <CCardBody className="py-4">
                  <LoginForm
                    onSubmit={onSubmit}
                    errors={errors}
                    session_timeout={session_timeout ? true : false}
                  />
                </CCardBody>
                <CCardFooter>
                  <span className="ml-1">&copy; 2022 Cyber Security Cloud, Inc.</span>
                </CCardFooter>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    errors: state.auth.errors,
    session_timeout: state.unauthorized_error.response
  };
};

export default connect(mapStateToProps, { signIn })(Login);
