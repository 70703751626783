export const SIGN_OUT = 'SIGN_OUT';
export const UNAUTHORIZED = 'UNAUTHORIZED';
export const TWO_FACTOR_AUTH_TOKEN = 'TWO_FACTOR_AUTH_TOKEN';

export const PASSWORD_EDIT_SUCCESS = 'PASSWORD_EDIT_SUCCESS';
export const PASSWORD_EDIT_FORM_ERROR = 'PASSWORD_EDIT_FORM_ERROR';

export const CREATE_USER = 'CREATE_USER';
export const FETCH_USERS = 'FETCH_USERS';
export const FETCH_USER = 'FETCH_USER';
export const DELETE_USER = 'DELETE_USER';
export const EDIT_USER = 'EDIT_USER';

export const SEND_PASSWORD_RESET_MAIL = 'SEND_PASSWORD_RESET_MAIL';
export const RESET_PASSWORD = 'RESET_PASSWORD';

export const FETCH_ATTACK_LOGS = 'FETCH_ATTACK_LOGS';
