import { createSlice } from "@reduxjs/toolkit";

const attackLogFiltersSlice = createSlice({
  name: 'attackLogFilters',
  initialState: {
    reported_at_start: '',
    reported_at_end: '',
    threat_level: '',
    threat_category: '',
    rule_action: '',
    dest_host_name: '',
    dest_host_key: '',
    src_country: '',
    src_ip: '',
    rule_id: ''
  },
  reducers: {
    setAttackLogFilter(state, action) {
      return action.payload;
    }
  }
});

export const { setAttackLogFilter } = attackLogFiltersSlice.actions;
export const selectAttackLogFilter = (state) => state.attack_log_filters;

export default attackLogFiltersSlice.reducer;
