import React from 'react';
import { connect } from 'react-redux';
import {
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CRow
} from "@coreui/react";

import PasswordResetMailForm from "./forms/PasswordResetMailForm";
import { sendPasswordResetMail } from "../actions/password";

const PasswordResetMail = ({ sendPasswordResetMail }) => {
  const onSubmit = (formValues) => {
    sendPasswordResetMail(formValues);
  };

  return (
    <div className="c-app c-default-layout flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md="6">
            <CCardGroup>
              <CCard className="p-4">
                <CCardBody>
                  <PasswordResetMailForm onSubmit={onSubmit} />
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  );
};

export default connect(null, { sendPasswordResetMail })(PasswordResetMail);
