import React, { useRef, useCallback } from "react";
import { Field, reduxForm } from "redux-form";
import { Link } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import {
  CButton,
  CCol,
  CForm,
  CInput,
  CInputGroup,
  CInputGroupPrepend,
  CInputGroupText,
  CInvalidFeedback,
  CRow,
  CAlert,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";

const LoginForm = ({
  handleSubmit,
  submitting,
  onSubmit,
  errors,
  session_timeout,
}) => {
  const recaptchaRef = useRef();
  const sitekey = process.env.REACT_APP_RECAPTCHA_SITEKEY;

  const renderError = ({ error, touched }) => {
    if (touched && error) {
      return (
        <CInvalidFeedback
          className="help-block"
          style={{ display: "block" }}
          color="danger"
        >
          {error}
        </CInvalidFeedback>
      );
    }
  };

  const renderInput = useCallback(({ input, label, type, icon, meta }) => {
    return (
      <CInputGroup
        style={{
          width: "304px",
          marginRight: "auto",
          marginLeft: "auto",
          marginTop: "15px",
        }}
      >
        <CInputGroupPrepend>
          <CInputGroupText>
            <CIcon name={icon} />
          </CInputGroupText>
        </CInputGroupPrepend>
        <CInput {...input} type={type} placeholder={label} />
        {renderError(meta)}
      </CInputGroup>
    );
  }, []);

  const formSubmit = (formValues) => {
    formValues.recaptcha_value = recaptchaRef.current.getValue();
    onSubmit(formValues, recaptchaRef.current);
  };

  const loginAlert = () => {
    if (errors !== null) {
      return errors;
    } else if (session_timeout) {
      return "セッションがタイムアウトしました";
    } else {
      return false;
    }
  };

  return (
    <CForm onSubmit={handleSubmit(formSubmit)}>
      <CAlert color="primary" show={loginAlert() ? true : false}>
        {loginAlert()}
      </CAlert>
      <Field
        component={renderInput}
        label="メールアドレス"
        name="email"
        type="email"
        icon="cil-envelope-closed"
      />
      <Field
        component={renderInput}
        label="パスワード"
        name="password"
        type="password"
        icon="cil-lock-locked"
      />
      <div className="form_group_recaptcha" style={{ marginTop: "20px" }}>
        <ReCAPTCHA ref={recaptchaRef} sitekey={sitekey} />
      </div>
      <CRow style={{ marginTop: "15px" }}>
        <CCol xs="12" className="text-center">
          <CButton
            style={{ width: "304px" }}
            color="primary"
            disabled={submitting}
            type="submit"
          >
            ログイン
          </CButton>
        </CCol>
        <CCol xs="12" className="text-center" style={{ marginTop: "5px" }}>
          <Link to="/password">パスワードをお忘れですか？</Link>
        </CCol>
      </CRow>
    </CForm>
  );
};

const validate = (formValues) => {
  const errors = {};

  if (!formValues.email) {
    errors.email = "メールアドレスを入力してください";
  }

  if (!formValues.password) {
    errors.password = "パスワードを入力してください";
  }

  return errors;
};

export default reduxForm({
  form: "loginForm",
  validate,
})(LoginForm);
