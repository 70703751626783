import React from 'react';
import { useDispatch } from 'react-redux';
import {
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CRow
} from "@coreui/react";

import PasswordResetForm from "./forms/PasswordResetForm";
import { resetPassword } from "../actions/password";

const PasswordReset = () => {
  const dispatch = useDispatch();
  const onSubmit = (formValues) => {
    dispatch(resetPassword(formValues));
  };

  return (
    <div className="c-app c-default-layout flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md="6">
            <CCardGroup>
              <CCard className="p-4">
                <CCardBody>
                  <PasswordResetForm onSubmit={onSubmit}/>
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  );
};

export default PasswordReset;
